import React from 'react'
import { Menu, Footer, useLanguage, BaseLayout, withLanguageProvider, MainLayout } from '@tmp'
import { graphql, PageProps } from 'gatsby'
import cn from 'classnames'
import { allLocales } from '@src/utils/locale'

import BookMeetingSteps from '@src/components/BookMeetingSteps'
import { MeetingRooms } from '@src/utils/types'

const PageTemplate = ({ data: { allContentfulMeetingRoom } }: PageProps<MeetingRooms>) => {
  const { t, locale } = useLanguage()
  const rooms = allContentfulMeetingRoom.nodes.filter(room => room.node_locale === locale)

  return (
    <BaseLayout
      defaultSeoOverwrites={{
        title: 'fundarherbergi',
        ogTitle: 'Harpa - fundarherbergi',
      }}
    >
      <main className={cn('relative pb-40 transition-colors duration-1000')}>
        <Menu localizedUrls={allLocales.map(locale => ({ locale, route: { type: 'fundarherbergi' } }))} />

        <div className=''>
          <BookMeetingSteps t={t} locale={locale} rooms={rooms} />
        </div>
      </main>
      <Footer className='-mt-40 bg-white pt-50 footer-container-clip' />
    </BaseLayout>
  )
}

export default withLanguageProvider(PageTemplate)

export const query = graphql`
  query meetingRooms {
    allContentfulMeetingRoom {
      nodes {
        capacity
        capacityDescription
        imageGallery {
          __typename
          id
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, quality: 85, placeholder: BLURRED)
          }
        }
        featureImage {
          gatsbyImageData(layout: FULL_WIDTH, quality: 85, width: 1200, height: 806, placeholder: BLURRED)
        }
        infoList {
          description
          id
          infoItem
        }
        node_locale
        title
        contentfulid
        roomDescription {
          roomDescription
        }
      }
    }
  }
`
